<template>
	<div>
		<div class="mt-3">
			<b-form @submit.prevent="unAssignedAwb">

				<div class="row justify-content-center">
					<b-form-group 
						id="mawb-field" 
						label="MAWB" 
						label-for="mawb"
						class="mb-28 col-md-3"
					>
						<b-form-input 
							v-model="mawb" 
							id="mawb"
							type="text"
							placeholder="MAWB"
							required 
							trim
						></b-form-input>
					</b-form-group>

					<b-form-group 
						id="bagcode-field" 
						label="Bag Code" 
						label-for="bagcode" 
						class="mb-30 col-md-3"
					>
						<b-form-input
							id="bagcode"
							v-model="bagcode" 
							type="text"
							placeholder="Bag Code"
							trim
							required 
						></b-form-input>
					</b-form-group>

					<b-button 
						type="submit" 
						:disabled="submitting"
						variant="primary" 
						size="lg" 
						class="mt-4 submit_button"
					>
						Submit <b-spinner style="width: 26px; height: 26px;" v-if="submitting" class="ml-10"></b-spinner>
					</b-button>
				</div>

			</b-form>
		</div>

		<div class="respose-section mt-5" v-if="awbs.length">

			<div class="border-bottom"></div>
			
			<div class="d-flex justify-content-center">
				
				<b-list-group class="w-25 mt-5 d-flex justify-content-center">

					<div class="d-flex justify-content-center mb-3 heading_text">
						{{  'Please de-bag the following AWB' }}
					</div>

					<b-list-group-item 
						v-for="awb in awbs" 
						:key="awb" 
						class="d-flex justify-content-center"
					>
						<span> {{ awb }}</span>
					</b-list-group-item>

				</b-list-group>
			</div>

		</div>

	</div>
</template>

<script>

export default {

	name: "mawboutliers",
	title: "MAWB-AWB Outliers",

	data() {

		return {
			mawb: '',
			bagcode: '',
			submitting: false,
			awbs: [],
		}

	},

	methods: {

		async unAssignedAwb() {

			try {

				this.submitting = true;

				let data = {
					"mawb" : this.mawb,
					"bagcode": this.bagcode
				}

				const res = await this.axios.post('/get-unassigned-awb', data);

				if(res.data.success) {
					this.awbs = res.data.awb
				}
				else {
					this.popToast('Failed!', res.data.message, 'danger')
					this.awbs = [];
				}

				this.submitting = false;

			}
			catch (err) {
				console.log('err', err);
				this.submitting = false;

			}
		}
	}
}
</script>
<style lang="scss" scoped>
	.submit_button {
		height: 3rem;
	}
	.heading_text {
		font-size: 18px;
		font-weight: 600;
	}
</style>
